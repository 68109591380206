import { P as PO_TBL_COLUMNS, d as PO_TBL_CONFIG } from "./cloneDeep.js";
import { e as URL_POT_OPTIONS } from "./api-endpoints.js";
import { n as normalizeComponent } from "./index.js";
import "@kraftheinz/common";
import "numeral";
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "base-version-input__container" }, [_c("colorful-editable-table", { attrs: { "tbl-width": "100%", "tbl-columns": _vm.tblColumns, "tbl-rows": _vm.tblConfig, "tbl-data": _vm.tblData, "tbl-name": "base", "gap-x": 2, "gap-y": 1, "uneditable": _vm.uneditable, "sync-collapse": _vm.isCollapsed, "is-account-group": _vm.isAccountGroup, "allow-clear-header": "" }, on: { "change": _vm.onChange, "scroll": _vm.onScroll, "pasted": _vm.onPaste } })], 1);
};
var staticRenderFns = [];
const ColorfulEditableTable = () => import("./ColorfulEditableTable.js");
const __vue2_script = {
  components: {
    ColorfulEditableTable
  },
  props: {
    tblData: {
      type: Array,
      default: null
    },
    uneditable: {
      type: Boolean,
      default: false
    },
    isCollapsed: {
      type: Boolean,
      default: true
    },
    isAccountGroup: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      tblColumns: PO_TBL_COLUMNS,
      urlOptions: URL_POT_OPTIONS
    };
  },
  computed: {
    tblConfig() {
      return this.isAccountGroup ? this.insertFieldForDistributor() : PO_TBL_CONFIG;
    }
  },
  methods: {
    insertFieldForDistributor() {
      const marginSupportFields = [
        {
          key: "margin-support-o-i",
          criteriaGroup: {
            text: "Perm. OI",
            rowSpan: 2
          },
          criteria: "Margin Support OI$ (DTS)",
          dataIndex: "marginSupportOIStore",
          numberFormat: "$0,0.00",
          suffixInput: "$",
          validationRules: "number|decimal:6,5"
        },
        {
          key: "margin-support-o-i-d-c",
          criteriaGroup: {
            text: "Perm. OI",
            rowSpan: 0
          },
          criteria: "Margin Support OI$ (DC)",
          dataIndex: "marginSupportOI",
          numberFormat: "$0,0.00",
          suffixInput: "$",
          validationRules: "number|decimal:6,5"
        }
      ];
      const indexMarginSupportIO = PO_TBL_CONFIG.findIndex((item) => item.key === "margin-support-o-i");
      let clonedTblConfig = [...PO_TBL_CONFIG];
      if (indexMarginSupportIO > -1) {
        clonedTblConfig.splice(indexMarginSupportIO, 1, ...marginSupportFields);
      }
      return clonedTblConfig;
    },
    onChange(val) {
      this.$emit("change", val);
    },
    onScroll(val) {
      this.$emit("scrollX", val);
    },
    onPaste(pastedData) {
      this.$emit("onPaste", pastedData);
    }
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var BaseVersionInput = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { BaseVersionInput as default };
